/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import "style/style.scss"
import PropTypes from "prop-types"
import Header from "components/ui/Header"
import Footer from "components/ui/Footer"
import Head from "components/Layout/Head"
import css from "./style.module.scss"

export const Container = ({ children }) => (
  <div className={css.container}>{children}</div>
)
const Layout = ({ children, header = true, withBackground = false }) => (
  <div className={css.layout}>
    {withBackground && (
      <img className={css.background} src={"/homepage/background.png"} />
    )}
    <div className={css.page}>
      <Head />
      <Header />
      <div className={css.content}>
        <main>{children}</main>
        <Footer />
      </div>
    </div>
  </div>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
