import React from 'react';
import { UpTitle, Title, Text } from 'components/ui/Typography';
import css from './style.module.scss';

export const Section = ({ upTitle, title, children }) => (
  <div className={css.section}>
    {upTitle && <UpTitle>{upTitle}</UpTitle>}
    <Title>{typeof title === 'function' ? title() : title}</Title>
    {children}
  </div>
);

export default Section;
