import React, { useState } from 'react';
import { Input } from 'components/ui/Inputs';
import Button from 'components/ui/Button';
import { Formik, Field } from 'formik';
import {
  SectionTitle, SubTitle, Text,
} from 'components/ui/Typography';
import ModalBox from 'components/ui/ModalBox';
import { trackEvent } from 'shared/track';
import css from './style.module.scss';
import { addToInviteList } from './api';

const InviteBox = ({ onClose }) => {
  const [showSuccess, setShowSuccess] = useState(false);
  const onSubmit = async (values, actions) => {
    actions.setSubmitting(false);
    const response = await addToInviteList(values);
    if (response) {
      trackEvent('request_invite');
      setShowSuccess(true);
    }
  };
  return (
    <ModalBox transparent animate closable onClose={() => onClose()}>
      <div className={css.form}>
        {showSuccess && (
        <>
          <SectionTitle>
            ✨Thanks!✨
          </SectionTitle>
            {' '}
          <Text className={css.subtitle}>
            {' '}
We will send you an email as soon as we are ready to accept you 💜
          </Text>
        </>
        )}
        {!showSuccess && (
        <>
          <SectionTitle>We are still in beta!</SectionTitle>
          <Text className={css.subtitle}>
          We are still working on Metrical, and only inviting few people at the
          time... but you can always ask for an early access 😉
          </Text>
          <br />
          <br />
          <br />

          <Formik
            initialValues={{ email: '' }}
            onSubmit={onSubmit}
            render={({ handleSubmit, isSubmitting }) => (
              <>
                <form onSubmit={handleSubmit}>
                  <Field
                    placeholder="michael.scott@dundlermifflin.com"
                    className={css.input}
                    component={Input}
                    name="email"
                    type="email"
                    label="Your Email"
                  />
                  <div className={css.submitContainer}>
                    <Button link className={css.submit} type="submit" disabled={isSubmitting}>
                  Request an invite
                    </Button>
                  </div>
                </form>
                <Text className={css.info}>
You will receive an email from
                  {' '}
                  <b>hello@metrical.xyz</b>
                  {' '}
                  {' '}
when we will be ready to accept you.
                </Text>
              </>
            )}
          />
        </>
        )}
      </div>
    </ModalBox>
  );
};


export default InviteBox;
