import React from 'react';
import cn from 'classnames';
import { Link } from 'gatsby';
import { trackEvent } from 'shared/track';
import css from './style.module.scss';


const Button = ({
  to,
  children,
  onClick,
  className,
  type,
  link = false,
  secondary,
  big,
  small,
  external,
  style,
  target,
  track,
  linkStyle = 'line',
}) => {
  const classNames = cn(
    link ? css.link : css.button,
    link && linkStyle === 'line' && css.linkLine,
    secondary && css.secondary,
    big && css.big,
    small && css.small,
    className,
  );

  if (to) {
    if (external) {
      return (
        <a target={target} rel="noopener noreferrer" className={classNames} style={style} href={to} onClick={() => trackEvent(track)}>
          {children}
        </a>
      );
    }
    return (
      <Link className={classNames} style={style} to={to} onClick={() => trackEvent(track)}>
        {children}
      </Link>
    );
  }
  return (
    <button
      type={type || 'button'}
      className={classNames}
      style={style}
      onClick={() => {
        if (track) {
          trackEvent(track);
        }
        if (onClick) {
          onClick();
        }
      }}
    >
      {children}
    </button>
  );
};

export default Button;
