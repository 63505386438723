import React from 'react';
import { SectionTitle } from 'components/ui/Typography';
import Button from 'components/ui/Button';
import { Link, withPrefix } from 'gatsby';

import css from './style.module.scss';

export const Header = ({ onlyLogo }) => (
  <div className={css.header}>
    <Link className={css.left} to="/">
      <img
        src={withPrefix('/metrical.logo.small.png')}
        alt="Metrical Logo"
        className={css.logoImage}
      />
      {/* <SectionTitle className={css.logoTitle}>Metrical</SectionTitle> */}
    </Link>
    {!onlyLogo && (
      <div className={css.right}>
        <Button link external to="https://app.metrical.xyz/login">
          Sign in
        </Button>
      </div>
    )}
  </div>
);

export default Header;
