import React from 'react';
import cn from 'classnames';
import {
  animated,
  useSpring,
} from 'react-spring';
import Animator from 'components/ui/Animator';
import css from './style.module.scss';

const ModalBox = ({
  children,
  className,
  withHeader = false,
  withShadow = false,
  closable = true,
  size,
  onClose,
}) => {
  const close = () => {
    if (closable) {
      if (onClose) {
        onClose();
      } else {
        window.history.back();
      }
    }
  };
  const openProps = useSpring({
    config: { duration: 200 },
    from: {},
    to: {},
  });
  return (
    <animated.div
      className={cn(css.modal)}
      style={openProps}
    >

      {withHeader && (
        <div className={css.modalBoxHeader}>
          <img
            src="/assets/metrical.logo.small.png"
            alt="Metrical Logo"
            className={css.logoImage}
          />
        </div>
      )}
      <div
        className={cn(
          css.inner,
          withShadow && css.withShadow,
          css[size],
          className,
        )}
      >
        <Animator className={css.modalContent}>
          {closable && (
          <div className={css.closeButton} onClick={close}>
         Close
          </div>
          )}
          {children}
        </Animator>
      </div>
    </animated.div>
  );
};

export default ModalBox;
