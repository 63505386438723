import React, { useState } from 'react';
import RegisterModal from 'components/Register';
import Button from 'components/ui/Button';

const InviteButton = ({
  children,
  className,
  type,
  link = false,
  secondary,
  big,
  small,
  style,
}) => {
  const [openModal, setOpenModal] = useState(false);
  // const classNames = cn(
  //   link ? css.link : css.button,
  //   secondary && css.secondary,
  //   big && css.big,
  //   small && css.small,
  //   className,
  // );


  return (
    <>
      {openModal && <RegisterModal onClose={() => setOpenModal(false)} />}
      <Button
        type={type || 'button'}
        // className={classNames}
        style={style}

        onClick={() => {
          setOpenModal(true);
        }}
      >
        {children}
      </Button>
    </>
  );
};

export default InviteButton;
