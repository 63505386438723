import React from 'react';
import cn from 'classnames';
import css from './style.module.scss';

export const Title = ({ children, className }) => (
  <h1 className={cn(css.title, className)}>{children}</h1>
);

export const UpTitle = ({ children, className }) => (
  <h4 className={cn(css.upTitle, className)}>{children}</h4>
);

export const SubTitle = ({ children, className }) => (
  <h3 className={cn(css.subTitle, className)}>{children}</h3>
);

export const SectionTitle = ({ children, className }) => (
  <h3 className={cn(css.sectionTitle, className)}>{children}</h3>
);

export const SectionSubTitle = ({ children, className }) => (
  <h5 className={cn(css.sectionSubTitle, className)}>{children}</h5>
);

export const Text = ({ children, big, className }) => (
  <p className={cn(css.text, big && css.bigText, className)}>{children}</p>
);

export const SmallText = ({ children, className }) => (
  <p className={cn(css.smallText, className)}>{children}</p>
);

export default {};
