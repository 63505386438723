import React from 'react';
import { getYear } from 'date-fns';
import cn from 'classnames';
import css from './style.module.scss';
import Button from '../Button';

const FooterTitle = ({ children }) => <div className={css.title}>{children}</div>;

const Column = ({ children, title }) => (
  <div className={css.column}>
    <FooterTitle>{title}</FooterTitle>
    {children}
  </div>
);

const Footer = () => {
  const year = getYear(new Date());
  return (
    <div className={css.footer}>
      <Column title={`${year} Metrical`}>
        <p>
          Made with 💜 by
          {' '}
          <Button link linkStyle="none" className={css.about} to="/about">
            Francesco and Virginia
          </Button>
        </p>
        <Button
          className={cn(css.link, css.firstLink)}
          link
          external
          linkStyle="none"
          to=""
        >
          What is metrical?
        </Button>
      </Column>
      <Column title="Privacy & Docs">
        <Button
          className={css.link}
          link
          external
          linkStyle="none"
          to="https://docs.metrical.xyz/privacy/privacy-policy"
        >
          Our privacy policy
        </Button>
        <Button
          className={css.link}
          link
          external
          linkStyle="none"
          to="https://docs.metrical.xyz/privacy/what-we-track"
        >
          What we track
        </Button>
        <Button
          className={css.link}
          link
          external
          linkStyle="none"
          to="https://docs.metrical.xyz/"
        >
          Documentation
        </Button>
      </Column>

      <Column title="Contact us">
        <Button
          className={css.link}
          link
          external
          linkStyle="none"
          to="mailto:hello@metrical.xyz"
        >
          Email
        </Button>
        <Button
          className={css.link}
          link
          external
          linkStyle="none"
          to="https://telegram.me/sono_franco"
        >
          Telegram
        </Button>
        <Button
          className={css.link}
          link
          external
          linkStyle="none"
          to="https://twitter.com/urcoilbisurco"
        >
          Twitter
        </Button>
      </Column>
    </div>
  );
};

export default Footer;
