import React from 'react';
import cn from 'classnames';
import css from './style.module.scss';

export const Label = ({ name, children }) => (
  <label htmlFor={name} className={css.label}>
    {children}
  </label>
);

export const Input = ({
  label,
  name,
  field,
  type,
  placeholder,
  value,
  onChange,
  className,
  responsive,
  description,
  errors,
  disabled,
}) => (
  <div className={cn(css.input, className)}>
    {label && (
      <div className={css.labelRow}>
        <Label name={name}>{label}</Label>
        {errors && <small className={css.errors}>{errors}</small>}
      </div>
    )}
    <input
      placeholder={placeholder}
      className={cn(css.inputField, responsive && css.responsive)}
      id={name}
      name={name}
      disabled={disabled}
      autoComplete="off"
      value={value}
      onChange={onChange}
      type={type}
      {...field}
    />
    {description && <small className={css.description}>{description}</small>}
  </div>
);

export default Input;
