import React from 'react';
import Helmet from 'react-helmet';
import { withPrefix } from 'gatsby';

const Head = () => (
  <Helmet>
    <title>Metrical</title>
    <meta name="apple-mobile-web-app-title" content="Metrical" />
    <meta name="application-name" content="Metrical" />
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta httpEquiv="x-ua-compatible" content="ie=edge" />
    <meta
      itemProp="name"
      content="Metrical - A privacy-first web analytics tool for everyone"
    />
    <meta
      name="description"
      content="Metrical is web analytics tool that is easy to use, privacy oriented and where you don't have to have a marketing degree to know how to use it."
    />
    <meta
      itemProp="image"
      content="https://metrical.xyz/assets/meta_image.png"
    />
    <meta
      property="og:title"
      content="Metrical - A privacy-first web analytics tool for everyone"
    />
    <meta
      property="og:description"
      content="Metrical is web analytics tool that is easy to use, privacy oriented and where you don't have to have a marketing degree to know how to use it."
    />
    <meta
      property="og:image"
      content="https://metrical.xyz/assets/meta_image.png"
    />
    <meta property="og:url" content="https://metrical.xyz" />
    <meta property="og:type" content="website" />
    <meta property="og:locale" content="en_US" />
    <meta name="twitter:card" content="summary_large_image" />
    <meta
      name="twitter:title"
      content="Metrical - A privacy-first web analytics tool for everyone"
    />
    <meta
      name="twitter:description"
      content="Metrical is web analytics tool that is easy to use, privacy oriented and where you don't have to have a marketing degree to know how to use it."
    />
    <meta
      name="twitter:image"
      content="https://metrical.xyz/assets/meta_image.png"
    />
    <meta name="apple-mobile-web-app-capable" content="yes" />
    <meta name="theme-color" content="#ffffff" />
    <meta
      name="msapplication-config"
      content={withPrefix('/assets/browserconfig.xml')}
    />
    <link
      rel="shortcut icon"
      href={withPrefix('/assets/favicon/favicon-32x32.png')}
    />
    <link
      rel="apple-touch-icon"
      sizes="180x180"
      href={withPrefix('/assets/favicon/apple-touch-icon.png')}
    />
    <link
      rel="icon"
      type="image/png"
      sizes="32x32"
      href={withPrefix('/assets/favicon/favicon-32x32.png')}
    />
    <link
      rel="icon"
      type="image/png"
      sizes="16x16"
      href={withPrefix('/assets/favicon/favicon-16x16.png')}
    />
    <link
      rel="manifest"
      href={withPrefix('/assets/favicon/site.webmanifest')}
    />
  </Helmet>
);

export default Head;
