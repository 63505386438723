import React from 'react';
import { useSpring, animated } from 'react-spring';

const Animator = ({ children, className, opts = { delay: 100 } }) => {
  const props = useSpring({
    from: {
      opacity: 0,
      transform: 'translateY(100px)',
    },
    to: {
      opacity: 1,
      transform: 'translateY(5px)',
    },
    ...opts,
  });
  return <animated.div className={className} style={props}>{children}</animated.div>;
};

export default Animator;
