import axios from 'axios';

export const addToInviteList = async ({ email }) => {
  const response = await axios.post('https://api.metrical.xyz/invites', { email });
  return response;
};

export default {
  addToInviteList,
};
